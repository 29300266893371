<template>
  <div class="index.vue">
    <h2 class="ass"> 这是index.vue页面 </h2>
  </div>
</template>

<script>
export default {
  name: 'Mine',

  data () {
    return {
    }
  },

  methods: {}
}
</script>

<style lang="less" scoped>
.ass {
  color: red;
}
</style>
